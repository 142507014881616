export const reviewData = [
    {
        review:
            "If you are interested in booking an appointment or have questions, please contact us at (617) 380-8566 for inquiries",
        name: 'J',
    },
    {
        review:
            "iDesigns Studio by J's website is still under construction. However, we are in business and would love to hear from you!",
        name: 'J',
    },
];
