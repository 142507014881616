export const menuData = {
  lashServices: [
        { name: 'Classic Lashing is a method where one lash extension is adhered to one natural lash. This method is also known as 1 to 1. Classic lashes are perfect for clients who want to add more length and volume to your natural lash line. This method creates a natural and gorgeous look.', price: '' },
        { name: '', price: '' },
        { name: '====================================', price: '' },
        { name: '', price: '' },
        { name: 'Classic FullSet', price: '$195' },
        { name: '1-2 Week Fill', price: '$75' },
        { name: '2-3 Week Fill', price: '$95' },
        { name: 'Special Occasion Fill', price: '$50' },
        { name: 'Lash Removal', price: '$50' },
        { name: '', price: '' },
        { name: '====================================', price: '' },
        { name: '', price: '' },
        { name: 'Hybrid Volume Lashing is a 70-30 mix of classic lashes and volume lashes. This combination is a perfect balance between a glamorous and natural look and gives the best of both worlds.', price: '' },
        { name: '', price: '' },
        { name: '====================================', price: '' },
        { name: '', price: '' },
        { name: 'Hybrid Volume FullSet', price: '$250' },
        { name: '1-2 Week Fill', price: '$100' },
        { name: '2-3 Week fill', price: '$125' },
        { name: '', price: '' },
        { name: '====================================', price: '' },
        { name: '', price: '' },
        { name: 'Standard Volume Lashing is a method where multiple light-weight lashes are applied to one single natural eyelash. If two eyelash extensions are applied to one natural lash it is described as 2D, if three eyelash extensions are applied to one natural eyelash then it is described as 3D and so on. Volume lashes are lighter, fluffier and give the client a fuller look. Volume lashes are prefect for clients who have sparse lashes, bald spots or just want a more dramatic and thicker look. ', price: '' },
        { name: '', price: '' },
        { name: '====================================', price: '' },
        { name: '', price: '' },
        { name: 'Standard Volume FullSet', price: '$325' },
        { name: '1-2 Week Fill', price: '$125' },
        { name: '2-3 Week Fill', price: '$145' },
        { name: '', price: '' },
        { name: '====================================', price: '' },
        { name: '', price: '' },
        { name: 'Lash Lift & Tint is a lash enhancement that will give your natural lashes the appearance of being darker, thicker, and longer.  This treatment is an excellent alternative to eyelash extensions and can last up to 4-6 weeks.  ', price: '' },
        { name: '', price: '' },
        { name: '====================================', price: '' },
        { name: '', price: '' },
        { name: 'Lift & Tint', price: '$95' },
        { name: 'Lash Lift', price: '$75' },
        { name: 'Lash Tint', price: '$35' },
    ],

  browServices: [
    { name: 'Microblading', price: '$650' },
    { name: 'Retouch 2-3 Months', price: '$150' },
    { name: 'Shading', price: '$650' },
    { name: 'Retouch 2-3 Months', price: '$150' },
    { name: 'Blade and Shade', price: '$750' },
    { name: 'Retouch 2-3 Months', price: '$160' },
    { name: 'Brows Lamination', price: '$120' },
  ],

  waxServices: [
    { name: 'Eyebrow Wax', price: '$20' },
    { name: 'Upper Lip Wax', price: '$15' },
    { name: 'Chin Wax', price: '$15' },
    { name: 'Sideburns', price: '$20' },
    { name: 'Full Face Wax', price: '$50' },
    { name: 'Under Arms', price: '$25' },
    { name: 'Full Arms', price: '$50' },
    { name: 'Half Arms', price: '$35' },
    { name: 'Half Legs', price: '$45' },
    { name: 'Full Legs', price: '$70' },
    { name: 'Brazilian', price: '$95' },
    { name: 'Bikini', price: '$50' },
    { name: 'Back', price: '$50' },
    { name: 'Chest', price: '$40' },
  ],

   /* promotions: [
        { name: 'To celebrate our grand opening, iDesigns Studio by J is offering:' },
        { name: 'All FullSets are currently 20% off' },
        { name: 'Blade and Shade combo is $300 off' },
        { name: 'Microblading is $300 off' },
        { name: '', price: '' },
        { name: '', price: '' },
        { name: '', price: '' },
     ], */

};
